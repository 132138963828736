/* ImageSlider.css */
.slider-image-container {
    overflow: hidden;
    box-sizing: border-box;
    margin-inline: 200px;
  }
  
  .slider-track {
    display: flex;
    width: calc(300 * 10); /* Adjust this to match the total width of your images */
    animation: scroll 30s linear infinite;
  }
  
  .slides {
    flex: 0 0 auto;
    /* width: 100vw; */
  }
  
  .slider-images {
    width: 180px;
    height: auto; /* Adjust this to maintain the aspect ratio */
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100.3vw); /* Adjust this to match the total width of the first set of images */
    }
  }
  

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 3s ease-out, padding 2s ease-out;
  }
  
  .accordion-content.show {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 200px; /* This will be overridden by inline style */
  }

.accordion-button {
    cursor: pointer;
}

.accordion-button.active .accordion-icon i {
    transform: rotate(45deg);
    /* Example to change the plus icon to a minus */
    transition: transform 0.3s ease;
}
/* src/DropdownSearch.css */
.dropdown-search-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.dropdown-search-container label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-right: 10px;
}

.dropdown-search-container select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.dropdown-search-container button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown-search-container button:hover {
    background-color: #0056b3;
}
/* src/StudyConcerns.css */
.study-concerns-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #000000;
    padding-inline: 60px;
}

.study-concerns-container h3 {
    text-align: left;
    color: #000000;
    margin-bottom: 20px;
}

.study-concerns-container h5 {
    text-align: left;
    color: #000000;
    margin-bottom: 20px;
    font-size: 16;
}

.study-concerns-container h4 {
    color: #000000;
    margin-bottom: 15px;
    margin-top: 15px;
}

.study-concerns-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.study-concerns-container p{
    text-align: "justify";
}
.study-concerns-container ul {
    margin-bottom: 20px;
}

.study-concerns-container a {
    color: #000000;
    text-decoration: none;
}

.study-concerns-container a:hover {
    text-decoration: underline;
}

.study-concerns-container strong {
    font-weight: bold;
}
.button {
    font-size: 15px;
    width: 100%;
    height: 40px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    /* transition: background 0.3s ease; */
}

.button:hover {
    background: var(--gradient-bg2); /* Use CSS variables for colors if available */
}
.sec-title h4 {
    text-align: left;
    color: #fff;
    font-size: 24px;
    /* margin-bottom: 20px; */
  }
  .tab-desc {
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 25px;
    text-align: justify;
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
  .tab-title {
    font-weight: 600;
    margin-bottom: 25px;

    @media (max-width: 575px) {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
  .tab-title {
    font-weight: 600;
    margin-bottom: 25px;

    @media (max-width: 575px) {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
  .course-item {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  .course-image {
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .course-content {
    text-align: center;
  }
  
  .course-face {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .viewall-btn div {
    cursor: pointer;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .viewall-btn div:hover {
    background-color: #0056b3;
  }
  
  